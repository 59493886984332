import React, { useEffect } from "react";
import "./ItemModal.scss";
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from "react-router";
import { faPlay, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeModal } from "../../Redux/Modal/modal-actions";
import { updateViewCount } from "../../Redux/Movie/movie-actions";
import GetMainTitle, { GetOverview, GetIsedolMainTitle, GetIsedolOverview } from "../../Utils/SplitTitle";
import SplitTimeStamp, { TransToMinute } from "../../Utils/SplitTimeStamp";
import wakSeries from "../../Assets/wakseries.png";
import isedolseries from "../../Assets/isedolseries.png";

function ItemModal({ history }) {

    const dispatch = useDispatch();
    const modalType = useSelector(state => state.modal.modalType);

    const isedolItem = useSelector(state => state.isedol.isedolCast);
    const woowakgoodItem = useSelector(state => state.movie.movieCast);

    const item = modalType === "IsedolModal" ? isedolItem : woowakgoodItem;
    const { maxres, high, videoId, description, title, category } = item[0];
    const timeStampObjects = SplitTimeStamp(description);

    useEffect(() => {
        disableScroll();
        return () => enableScroll();
    }, []);

    return (
        <div className="modal-item">
            <img
                src={`${maxres}`}
                alt="item"
                className="modal-item__img"
            />
            <FontAwesomeIcon className="modal-item__close" icon={faClose} onClick={() => { dispatch(closeModal()); }} />
            <div className="modal-item__text">
                {title !== "event" ?
                    <div className="modal-item__wakseries">
                        <img
                            src={category === "woowakgood" ? wakSeries : isedolseries}
                            alt={category === "woowakgood" ? wakSeries : isedolseries}
                            className={category === "woowakgood" ? "modal-item__logo" : "modal-item__isedol-logo"}
                        />
                        <span className="modal-item__logo-text">{ category === "woowakgood" ? '시리즈' : null }</span>
                        <h1 className="modal-item__title">{category === "woowakgood" ? GetMainTitle(title) : GetIsedolMainTitle(category)}</h1>
                        <span className="modal-item__overview">{category === "woowakgood" ? GetOverview(title) : GetIsedolOverview(title)}</span>
                    </div> :
                    <div className="modal-item__wakseries">
                        <img
                            src={require(`../../Assets/Event/event-logo.png`).default}
                            alt="event-logo"
                            className="modal-item__event-logo"
                        />
                        <img
                            src={require(`../../Assets/Event/event-title.png`).default}
                            alt="event-title"
                            className="modal-item__event-title"
                        />
                        <span className="modal-item__overview">{high}</span>
                    </div>
                }
                <div className="modal-item__btn-container">
                    <button className="modal-item__play" onClick={() => { history.push(`/movies/${videoId}/youtube`); dispatch(closeModal()); updateViewCount(videoId); }}>
                        <FontAwesomeIcon icon={faPlay} className="modal-item__icon" />
                        재생
                    </button>
                </div>
            </div>
            <div className="modal-item__time-container">
                <span className="modal-item__time-desc">타임라인</span>
                {timeStampObjects.length === 0 ? <div className="modal-item__time-desc">타임라인 정보가 없습니다.</div>  : timeStampObjects.map(item => (
                    <div className="modal-item__time-border" key={TransToMinute(item.timestamp)}
                        onClick={() => {
                            history.push({ pathname: `/movies/${videoId}/youtube`, state: { timeFromModal: TransToMinute(item.timestamp) } });
                            dispatch(closeModal());
                            updateViewCount(videoId);
                        }}
                    >
                        <span className="modal-item__time-btn">
                            {item.timestamp}<br/><br/>{item.description}
                        </span>
                    </div>
                ))
                }
            </div>
        </div>
    );
};

function disableScroll() {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
};

function enableScroll() {
    const scrollY = document.body.style.top;
    document.body.style.cssText = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};

export default withRouter(ItemModal);
