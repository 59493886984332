import IsedolActionTypes from "./isedol-types";

const INITIAL_STATE = {
  isFetching: true,
  isAdditionalFetching: true,
  isedolItems: [],
  isedolGridItems: [],
  isedolCast: [],
  isedolVideos: ""
};

const isedolReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case IsedolActionTypes.SET_ISEDOL_DATA: {
      return { ...state, isedolItems: payload };
    }
    case IsedolActionTypes.SET_ISEDOL_GRID_DATA: {
      return { ...state, isedolGridItems: payload };
    }
    case IsedolActionTypes.SET_ISEDOL_DATA_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case IsedolActionTypes.SET_ISEDOL_ADDITIONAL_DATA_SUCCESS: {
      return { ...state, isAdditionalFetching: false };
    }
    case IsedolActionTypes.SET_ISEDOL_ADDITIONAL_DATA: {
      return {
        ...state,
        isedolCast: payload,
        isedolVideos: payload.length
          ? payload[0].videoId
          : "no_trailer_found"
      };
    }
    case IsedolActionTypes.SET_ISEDOL_CAST: {
      return { ...state, isedolCast: payload }
    }
    default:
      return state;
  }
};

export default isedolReducer;
