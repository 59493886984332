const urls = [
    `api/Collection/1`,
    `api/Collection/2`,
    `api/Collection/3`,
    `api/Collection/4`,
    `api/Collection/5`,
    `api/Collection/6`,
    `api/Collection/7`,
    `api/Collection/8`
];

export const fetchData = () => {
    return Promise.all(
        urls.map(items => {
            return fetch(items).then(response => response.json());
        })
    )
};

export const fetchAdditionalMovieData = () => {
    return fetch('api/Collection/GetVideoData').then(response => response.json());
};

export const fetchUpdateViewCount = (videoId) => {
    return fetch(`api/Update/${videoId}`).then(response => response.json());
}
