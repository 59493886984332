import YoutubeActionTypes from './youtube-types';

const INITIAL_STATE = {
	timestamps: [],
    player: null,
    timehidden: true
}

const youtubeReducer = (state = INITIAL_STATE, action) => {
	switch(action.type) {
        case YoutubeActionTypes.SET_TIME_DATA: 
		return {
			...state,
            timestamps: action.payload
		};
        case YoutubeActionTypes.SET_PLAYER_DATA: 
		return {
			...state,
            player: action.payload
        };
        case YoutubeActionTypes.TOGGLE_TIME_HIDDEN_MENU:
        return {
            ...state,
            timehidden: !state.timehidden
        };
		default:
		return state;
	}
}

export default youtubeReducer;