const INITIAL_STATE = {
  collection: [
      {
          id: 1,
          title: "새로 올라온 방송",
          start: 0,
          end: 13
      },
      {
          id: 2,
          title: "이세계아이돌",
          start: 13,
          end: 26
      },
      {
          id: 3,
          title: "고정멤버",
          start: 26,
          end: 39
      },
      {
          id: 4,
          title: "아카데미",
          start: 39,
          end: 52
      },
      {
          id: 5,
          title: "눕프로해커",
          start: 52,
          end: 65
      },
      {
          id: 6,
          title: "RANKING",
          start: 65,
          end: 75
      },
      {
          id: 7,
          title: "이런 방송은 어떠세요?",
          start: 75,
          end: 88
      }
  ]
};

const collectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default collectionReducer;
