import SearchActionTypes from './search-types';
import { fetchSearchData, fetchSearchDefaultData } from '../../Services/SearchService';

export function getSearchDefaultData() {
    return (dispatch) => {
        fetchSearchDefaultData().then(data => {
            dispatch({ type: SearchActionTypes.SET_SEARCH_DEFAULT_DATA, payload: data });
        });
    }
}

export function getSearchData(value) {
  return (dispatch) => {
      fetchSearchData(value).then(data => {
      dispatch({ type: SearchActionTypes.SET_SEARCH_DATA, payload: data });
    });
  }
}

export function addSearchScrollCount() {
    return (dispatch) => {
        dispatch({ type: SearchActionTypes.SET_SEARCH_SCROLL_COUNT });
    }
}