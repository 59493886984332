const IsedolActionTypes = {
  SET_ISEDOL_DATA_START: 'SET_ISEDOL_DATA_START',
  SET_ISEDOL_DATA: 'SET_ISEDOL_DATA',
  SET_ISEDOL_GRID_DATA: 'SET_ISEDOL_GRID_DATA',
  SET_ISEDOL_DATA_SUCCESS: 'SET_ISEDOL_DATA_SUCCESS',
  SET_ISEDOL_ADDITIONAL_DATA_SUCCESS: 'SET_ISEDOL_ADDITIONAL_DATA_SUCCESS',
  SET_ISEDOL_ADDITIONAL_DATA: 'SET_ISEDOL_ADDITIONAL_DATA',
  SET_ISEDOL_CAST: 'SET_ISEDOL_CAST'
};

export default IsedolActionTypes;