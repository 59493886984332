import React from "react";
import { Link } from "react-router-dom";
import "./NavMenu.scss";
import { withRouter } from "react-router";
import { selectCurrentUser } from "../../Redux/User/user-selectors";
import { connect } from "react-redux";
import { compose } from "redux";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleMenuHidden } from "../../Redux/User/user-actions";

const NavMenu = ({ ToggleMenuHidden }) => {
  return (
    <div className="overlay">
      <div className="overlay__exit" onClick={ToggleMenuHidden}>
        <FontAwesomeIcon icon={faTimes} className="overlay__exit-icon" />
      </div>
      <div className="overlay__content" onClick={ToggleMenuHidden}>

        <Link className="overlay__option" to="/movies">
          홈
        </Link>

        <Link className="overlay__option" to="/isedol">
          이세계아이돌
        </Link>

        <Link className="overlay__option" to="/hof">
            명예의전당
        </Link>

        <Link className="overlay__option" to="/gacha">
            Gacha!
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  ToggleMenuHidden: () => dispatch(ToggleMenuHidden())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavMenu);
