export const fetchSearchData = value => {
    const encode_value = encodeURIComponent(value);
    const url = `api/Search/${encode_value}`;
    return fetch(url).then(response => response.json());
};

export const fetchSearchDefaultData = () => {
    const url = 'api/Search/GetSearchVideoData';
    return fetch(url).then(response => response.json());
};
