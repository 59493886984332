import HofActionTypes from "./hof-types";

const INITIAL_STATE = {
  hofItems: []
};

const hofReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case HofActionTypes.GET_HOF_DATA: {
          return { ...state, hofItems: payload };
    }
    default:
      return state;
  }
};

export default hofReducer;
