import GachaActionTypes from "./gacha-types";

const INITIAL_STATE = {
  isOpen: false,
  gachaItem: [],
};

const gachaReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GachaActionTypes.GET_GACHA_DATA: {
          return { ...state, gachaItem: payload };
    }
    case GachaActionTypes.INIT_GACHA_DATA: {
          return { ...state, gachaItem: []};  
    }
    case GachaActionTypes.TOGGLE_OPEN_STATE: {
          return { ...state, isOpen: !state.isOpen };
    }
    default:
      return state;
  }
};

export default gachaReducer;
