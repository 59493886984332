const GetMainTitle = function (str) {
    var pos = str.indexOf('우왁굳');

    if (pos === -1)
        return '우왁굳 다시보기';
    else
        return str.substring(pos, pos + 14);
};

export const GetOverview = function (str) {
    var pos = str.indexOf('우왁굳');

    if (pos === -1)
        return str;
    else
        return str.substring(pos + 15);
}

export const GetIsedolMainTitle = function (str) {
    var title = "";

    switch (str) {
        case "ine":
            title += "아이네"
            break;
        case "jingburger":
            title += "징버거"
            break;
        case "lilpa":
            title += "릴파"
            break;
        case "jururu":
            title += "주르르"
            break;
        case "gosegu":
            title += "고세구"
            break;
        case "viichan":
            title += "비챤"
            break;
        case "isedol":
            title += "SNS 라이브"
            break;
        default:
            break;
    }

    if (str === "isedol") title += " 다시듣기";
    else title += " 다시보기";

    return title;
};

export const GetIsedolOverview = function (str) {
    return str;
}

export default GetMainTitle;
