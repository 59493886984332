import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    user => user.currentUser
);

export const selectToggleHidden = createSelector(
    [selectUser],
    user => user.hidden
);

export const selectToggleFirst = createSelector(
    [selectUser],
    user => user.firstLoading
);