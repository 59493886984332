const SplitTimeStamp = function (str) {
    if (str.indexOf(':') === -1)
        return [];

    const lines = str.split('\n');
    var filteredLines = lines.filter(item => item.length > 7 && item.split(':').length > 2);
    filteredLines = filteredLines.filter(item => !isNaN(Number(item.substring(item.indexOf(':') - 2, item.indexOf(':')))));

    if (filteredLines.length < 3)
        return [];

    const timeStamps = filteredLines.map(item => item.substring(item.indexOf(':') - 2, item.indexOf(':') + 6));
    const descriptions = filteredLines.map(item => item.substring(item.indexOf(':')+7));

    const timeStampObjects = [];

    for (let i = 0; i < timeStamps.length; i++) {
        timeStampObjects.push({ timestamp: timeStamps[i], description: descriptions[i] });
    }

    return timeStampObjects;
};

export const TransToMinute = function (time) {
    const minute = Number(time.split(':')[0]) * 3600 + Number(time.split(':')[1]) * 60 + Number(time.split(':')[2]);

    return minute;
}

export default SplitTimeStamp;
