import { createSelector } from 'reselect';

const selectSearch = state => state.search;

export const selectSearchItems = createSelector(
  [selectSearch],
  search => search.searchItems
);

export const selectSearchDefaultItems = createSelector(
    [selectSearch],
    search => search.searchDefaultItems
);

export const selectSearchScrollCount = createSelector(
    [selectSearch],
    search => search.searchScrollCount
);

export const selectSearchedMovieByTitle = (id) => createSelector(
  [selectSearchItems],
  searchItems => searchItems.filter(items => items.videoId === id)[0]
);

export const selectSearchedMovieDefault = (id) => createSelector(
    [selectSearchDefaultItems],
    searchDefaultItems => searchDefaultItems.filter(items => items.videoId === id)[0]
);

export const selectSearchedTVShowByTitle = (id) => createSelector(
  [selectSearchItems],
  searchItems => searchItems.filter(items => items.name ? (items.id === id) : null)
);


