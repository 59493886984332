import React from "react";
import { Link } from "react-router-dom";
import LogoWak from "../../Assets/wakflix.png";
import LogoProfile from "../../Assets/profile.png";
import LogoWakTime from "../../Assets/waktime.png";
import "./Header.scss";
import { withRouter } from "react-router";
import SearchBar from "../SearchBar/SearchBar";
import {
  selectCurrentUser,
  selectToggleHidden
} from "../../Redux/User/user-selectors";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleMenuHidden } from "../../Redux/User/user-actions";
import { connect } from "react-redux";
import { compose } from "redux";
import NavMenu from "./NavMenu";

const Header = ({ history, currentRoute, hidden, ToggleMenuHidden }) => {

    const profiles = require.context('../../Assets/Profiles', true);
    const profilesList = profiles.keys().map(profile => profiles(profile));

    const ProfileImageChange = () => {
        let index = Math.floor(Math.random() * profilesList.length);

        let profilePC = document.querySelector(".header__logo-profile-pc");
        let profileMobile = document.querySelector(".header__logo-profile-mobile");
        profilePC.setAttribute("src", profilesList[index].default);
        profileMobile.setAttribute("src", profilesList[index].default);
    };

  return (
    <div className="header">
      <div className="header__logo-box" onClick={() => history.push("/movies")}>
        <img src={LogoWak} alt="logo" className="header__logo" />
      </div>

      <div className="header__options">
        <div className="header__options-primary">
          <Link className="header__option" to="/movies">
                      홈
          </Link>

          <Link className="header__option" to="/isedol">
                      이세계아이돌
          </Link>

          <Link className="header__option" to="/hof">
                      명예의전당
          </Link>

          <Link className="header__option" to="/gacha">
                      Gacha!
          </Link>
        </div>

        <div className="header__searchbar">
            <SearchBar currentRoute={currentRoute} />
        </div>

        <div className="header__profile_pc">
                  <img src={LogoProfile} onClick={ProfileImageChange} alt="logo_profile" className="header__logo-profile-pc" />
                  <img src={LogoWakTime} onClick={() => { window.open("https://chromewebstore.google.com/detail/%EC%99%81%ED%83%80%EC%9E%84%EB%9D%BC%EC%9D%B8/ofopgloibjemgmbhlnpoimkljhmbblhd", "_blank") } } alt="logo_profile" className="header__logo-profile-pc" />
        </div>
      </div>

      <div className="header__profile_mobile" onClick={ProfileImageChange}>
          <img src={LogoProfile} alt="logo_profile" className="header__logo-profile-mobile" />
      </div>

      <FontAwesomeIcon
        icon={faBars}
        className="header__nav-menu-icon"
        onClick={ToggleMenuHidden}
      />
      {hidden ? null : <NavMenu />}


    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  hidden: selectToggleHidden(state)
});

const mapDispatchToProps = dispatch => ({
  ToggleMenuHidden: () => dispatch(ToggleMenuHidden())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
