import ModalActionTypes from "./modal-types";

const INITIAL_STATE = {
  modalType: "",
  isOpen: false
};

const modalReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ModalActionTypes.OPEN_MODAL: {
      return { ...state, modalType: payload, isOpen: true };
    }
    case ModalActionTypes.CLOSE_MODAL: {
      return { ...state, isOpen: false };
    }
    default:
      return state;
  }
};

export default modalReducer;
