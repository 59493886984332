import ModalActionTypes from "./modal-types";

export function openModal(type) {
    return dispatch => {
        dispatch({
            type: ModalActionTypes.OPEN_MODAL,
            payload: type
        });
    };
}

export function closeModal() {
    return dispatch => {
        dispatch({
            type: ModalActionTypes.CLOSE_MODAL
        });
    };
}
