import { combineReducers } from "redux";
import userReducer from "./User/user-reducer";
import movieReducer from "./Movie/movie-reducer";
import isedolReducer from "./Isedol/isedol-reducer";
import collectionReducer from "./Collection/collection-reducer";
import isedolCollectionReducer from "./IsedolCollection/isedolCollection-reducer";
import searchReducer from "./Search/search-reducer";
import youtubeReducer from "./Youtube/youtube-reducer";
import modalReducer from "./Modal/modal-reducer";
import hofReducer from "./HallOfFame/hof-reducer";
import gachaReducer from "./Gacha/gacha-reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user", "search", "movie", "isedol"]
};

const rootReducer = combineReducers({
    user: userReducer,
    movie: movieReducer,
    isedol: isedolReducer,
    collection: collectionReducer,
    isedolCollection: isedolCollectionReducer,
    search: searchReducer,
    youtube: youtubeReducer,
    modal: modalReducer,
    hof: hofReducer,
    gacha: gachaReducer
});

export default persistReducer(persistConfig, rootReducer);
