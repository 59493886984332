import SearchActionTypes from "./search-types";

const INITIAL_STATE = {
    searchItems: [],
    searchDefaultItems: [],
    searchScrollCount: 1
};

const searchReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SearchActionTypes.SET_SEARCH_DATA: {
      return { ...state, searchItems: payload, searchScrollCount: 1 };
    }
    case SearchActionTypes.SET_SEARCH_DEFAULT_DATA: {
    return { ...state, searchDefaultItems: payload };
    }
    case SearchActionTypes.SET_SEARCH_SCROLL_COUNT: {
        return { ...state, searchScrollCount: state.searchScrollCount + 1 };
    }
    default:
      return state;
  }
};

export default searchReducer;
