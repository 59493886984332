import React, { createRef } from "react";
import "./SearchBar.scss";
import { withRouter } from "react-router";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectSearchItems } from "../../Redux/Search/search-selectors";
import { getSearchData, getSearchDefaultData } from "../../Redux/Search/search-actions";
import { connect } from "react-redux";
import { compose } from "redux";

const debounceFunction = (callback, delay) => {
    let timer;
    return (...args) => {

        clearTimeout(timer);

        timer = setTimeout(() => callback(...args), delay);
    };
};

class SearchBar extends React.Component {
  constructor() {
    super();
    this.state = {
        currentPath: ""
      };
      this.divRef = createRef();
      this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var scrollY = window.scrollY;
        if (window.matchMedia("(min-width: 800px)").matches) {
            this.divRef.current.style.display = scrollY > 80 ? "none" : "block";
        }
        if (window.matchMedia("(max-width: 800px)").matches) {
            this.divRef.current.style.display = scrollY > 120 ? "none" : "block";
        }
    }


    setValue = debounceFunction((value) => {
        if (
            Number(value.length) === 1 &&
            this.props.currentRoute !== "/searchresults"
        ) {
            this.setState({ currentPath: this.props.currentRoute }, () =>
                this.props.history.push("/searchresults")
            );
        } else if (Number(value.length) === 0) {
            this.props.history.push(`${this.state.currentPath}`);
        } else if (Number(value.length) > 1) {
            this.props.history.push("/searchresults");
        }

        this.props.dispatch(getSearchDefaultData());

        if (value) this.props.dispatch(getSearchData(value));

    }, 275);

    handleChange = event => {
        this.setValue(event.target.value);

  };

  render() {
    return (
      <div className="container-1">
        <div className="container-2" ref={this.divRef}>
          <span className="search-icon">
             <FontAwesomeIcon icon={faSearch}/>
          </span>
          <input
            onInput={this.handleChange}
            type="search"
            id="search"
            placeholder=" 제목, 사람, 장르"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchItems: selectSearchItems(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(SearchBar);
