const INITIAL_STATE = {
  isedolCollection: [
      {
          id: 1,
          title: "SNS 라이브",
          start: 0,
          end: 13
      },
      {
          id: 2,
          title: "아이네",
          start: 13,
          end: 26
      },
      {
          id: 3,
          title: "징버거",
          start: 26,
          end: 39
      },
      {
          id: 4,
          title: "릴파",
          start: 39,
          end: 52
      },
      {
          id: 5,
          title: "주르르",
          start: 52,
          end: 65
      },
      {
          id: 6,
          title: "고세구",
          start: 65,
          end: 78
      },
      {
          id: 7,
          title: "비챤",
          start: 78,
          end: 91
      }
  ]
};

const isedolCollectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default isedolCollectionReducer;
