import MovieActionTypes from "./movie-types";
import {
  fetchData,
  fetchAdditionalMovieData,
  fetchUpdateViewCount
} from "../../Services/MovieService";
import { fetchGrid } from "../../Services/MovieGridService";

export const getMoviesSuccess = () => ({
  type: MovieActionTypes.SET_MOVIE_DATA_SUCCESS
});

export function getMovies() {
    return dispatch => {
        fetchData().then(data => {
            var newArray = Array.prototype.concat.apply([], data);
            dispatch({
                type: MovieActionTypes.SET_MOVIE_DATA,
                payload: newArray
            });
        });

    fetchGrid().then(data => {
        const movieGridData = data.slice(0,1);
        var newArray = Array.prototype.concat.apply([], movieGridData);
        dispatch({
            type: MovieActionTypes.SET_MOVIE_GRID_DATA,
            payload: newArray
        });
        dispatch(getMoviesSuccess());
    });
  };
}

export const getMoviesAdditionalSuccess = () => ({
  type: MovieActionTypes.SET_MOVIE_ADDITIONAL_DATA_SUCCESS
});

export function getAdditionalMovieData(id) {
    return dispatch => {
      fetchAdditionalMovieData().then(data => {
          var movieItem = data.filter(items => items.videoId === id);
          dispatch({
            type: MovieActionTypes.SET_MOVIE_ADDITIONAL_DATA,
              payload: movieItem
          });
          dispatch(getMoviesAdditionalSuccess());
    });
  };
}

export function setMovieCast(item) {
    return dispatch => {
        var newArray = []; newArray.push(item);
            dispatch({
                type: MovieActionTypes.SET_MOVIE_CAST,
                payload: newArray
            });
    };
}

export function updateViewCount(videoId) {
    return fetchUpdateViewCount(videoId);
}
