import React, { Suspense } from "react";
import "./App.scss";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Modal from 'react-modal';

import Header from "./components/Header/Header";
import ItemModal from "./components/ItemModal/ItemModal";
import Spinner from "./components/Spinner/Spinner";

import { setCurrentUser, ToggleFirstLoading } from "./Redux/User/user-actions";
import { selectCurrentUser, selectToggleFirst } from "./Redux/User/user-selectors";

import { closeModal } from "./Redux/Modal/modal-actions";
import { selectIsOpen } from "./Redux/Modal/modal-selectors";

class App extends React.Component {
    unsubscribeFromAuth = null;

    constructor(props) {
        super(props);
        this.state = {
            modalStyle: modalStylePC
        }
        this.windowResize = this.windowResize.bind(this);
    }

    componentDidMount() {
        document.title = "홈 - 왁플릭스";
        window.addEventListener('resize', this.windowResize);

        if (window.innerWidth > 800) {
            this.setState({ modalStyle: modalStylePC });
        }
        else {
            this.setState({ modalStyle: modalStyleMobile });
            this.props.history.push("/movies");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    windowResize = () => {
        if (window.innerWidth > 800) {
            this.setState({ modalStyle: modalStylePC });
        }
        else {
            this.setState({ modalStyle: modalStyleMobile });
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.firstLoading ?
                        <div className="start_container" style={{ display: this.props.firstLoading ? "block" : "none" }}>
                            <video className="startup" alt="startup" autoPlay muted playsInline onEnded={() => { this.props.ToggleFirstLoading(); }}>
                                <source src={`/videos/wakflix-startup.mp4`} type="video/mp4" />
                            </video>
                        </div>
                        : null
                }
                <div style={{ display: this.props.firstLoading ? "none" : "block" }}>
                    <div style={{ display: this.props.location.pathname.search("youtube") === -1 ? "block" : "none" }}>
                        <Header currentRoute={this.props.location.pathname} />
                    </div>
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                                <Route exact path="/" component={MoviesComponent} />
                                <Route path="/movies/:title/youtube" component={YoutubeComponent} />
                                <Route path="/movies" component={MoviesComponent} />
                                <Route path="/isedol" component={IsedolComponent} />
                                <Route path="/hof" component={HallOfFameComponent} />
                                <Route path="/gacha" component={GachaComponent} />
                                <Route path="/searchresults" component={SearchComponent} />
                        </Switch>
                    </Suspense>
                    <Modal isOpen={this.props.isOpen} onRequestClose={() => { this.props.closeModal(); }} center style={this.state.modalStyle} closeTimeoutMS={200}>
                        <ItemModal />
                    </Modal>
                </div>
            </div>
        );
    }
}

const MoviesComponent = React.lazy(() => import('./Pages/Movies/Movies'));
const IsedolComponent = React.lazy(() => import('./Pages/Isedol/Isedol'));
const YoutubeComponent = React.lazy(() => import('./Pages/YoutubePage/YoutubePage'));
const GachaComponent = React.lazy(() => import('./Pages/Gacha/Gacha'));
const HallOfFameComponent = React.lazy(() => import('./Pages/HallOfFame/HallOfFame'));
const SearchComponent = React.lazy(() => import('./components/SearchPage/SearchPage'));

const mapStateToProps = state => ({
    currentUser: selectCurrentUser(state),
    firstLoading: selectToggleFirst(state),
    isOpen: selectIsOpen(state)
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    ToggleFirstLoading: () => dispatch(ToggleFirstLoading()),
    closeModal: () => dispatch(closeModal())
});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(App);

Modal.setAppElement('#root')

const modalStylePC = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        border: '0px solid #222222',
        padding: '0px',
        backgroundColor: '#141414',
        borderRadius: '6px',
        position: 'absolute',
        height: '99vh',
        width: '45%',
        top: '21vh',
        left: '50%',
        transformOrigin: 'center top',
        transform: 'translate(-50%, -20%)',
        boxShadow: 'rgba(0, 0, 0, 0.75) 0px 3px 10px',
        marginBottom: '2em',
        minWidth: '850px',
        zIndex: '999'
    },
    overlay: {
        background: "rgba(0,0,0,0.4)"
    }
}

const modalStyleMobile = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        border: '0px solid #222222',
        padding: '0px',
        backgroundColor: '#141414',
        borderRadius: '6px',
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: '50%',
        transformOrigin: 'center top',
        transform: 'translate(-50%, -4.3%)',
        boxShadow: 'rgba(0, 0, 0, 0.75) 0px 3px 10px',
        zIndex: '999'
    },
    overlay: {
        background: "rgba(0,0,0,0.4)"
    }
}